import React from 'react'
import styled from 'styled-components';
import Appbar from '../Components/Appbar';
import Body from '../Components/Body';
import { Pagination } from '@material-ui/lab'
import { CircularProgress } from '@material-ui/core'
import { loadUserData, loadTotalPage } from '../Store/Actions/adminAction';
import { connect } from 'react-redux'

function Dashboard({ loadUserData, loadTotalPage, totalPages, loader }) {

    const [nameFilter, setNameFilter] = React.useState('');
    const [page, setPage] = React.useState(1);

    const handlePageChange = (event, value) => {
       setPage(value);
    };

    const searchChangedHandler = e => {
       setNameFilter(e.target.value);
    }

    React.useEffect(() => {
      loadUserData(nameFilter, page);
      loadTotalPage(nameFilter);  
    },[loadUserData, loadTotalPage, nameFilter, page])

    React.useEffect(() => {
      document.title = 'SMS Nepal Admin - Dashboard'
  },[])

    return (
       <Container>
           <Appbar nameFilter={nameFilter} searchChangedHandler={searchChangedHandler} />
           { !loader ?
                <React.Fragment>
                      <Body /> 
                      <PaginationContainer>
                            <Pagination count={totalPages} color="primary" page={page} onChange={handlePageChange}/>
                      </PaginationContainer>
                </React.Fragment>
                :
                <Loader>
                    <CircularProgress />
                </Loader>  
            }
       </Container>
    )
}

const mapStateToProps = state => {
  return {
      totalPages : state.adminReducer.totalPages,
      loader : state.adminReducer.userLoader,
  }
}

const mapDispatchToProps = dispatch => {
  return {
      loadUserData : (nameFilter, pageNo) => dispatch(loadUserData(nameFilter, pageNo)),
      loadTotalPage : nameFilter => dispatch(loadTotalPage(nameFilter))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)

const Container = styled.div`
  height : 100vh;
  width : 100%;
  box-sizing : border-box;
`;

const PaginationContainer = styled.div`
    display : flex;
    align-items : center;
    justify-content : center;
    height : 50px;
`
const Loader = styled.div`
    box-sizing : border-box;
    height : calc(100vh - 56px);
    width : 100%;
    display : flex;
    align-items : center;
    justify-content : center;  
`;