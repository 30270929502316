import { LOADING_USER_DATA, LOADING_USER_DATA_SUCCESS, LOADING_USER_DATA_ERROR, LOAD_PAGE, SETTING_SMS_LIMIT, SETTING_SMS_LIMIT_SUCCESS,
        SETTING_SMS_LIMIT_ERROR, VERIFING_USER, VERIFING_USER_SUCCESS, VERIFING_USER_ERROR } from '../Actions/actionTypes';

const initState = {
    user : [],
    userLoader : false,
    totalPages : 1,
    smsLimitLoader : false,
    verifingLoader : false
}

const adminReducer = (state = initState, action) => {
    switch(action.type){
        case LOADING_USER_DATA :
                            return {
                                ...state,
                                userLoader : true
                            }
        case LOADING_USER_DATA_SUCCESS : 
                            return {
                                ...state,
                                userLoader : false,
                                user : [...action.data]
                            }
        case LOADING_USER_DATA_ERROR : 
                            return {
                               ...state,
                               userLoader : false,
                            }
        case LOAD_PAGE : 
                       return {
                           ...state,
                           totalPages : action.totalPage
                       }         
        case SETTING_SMS_LIMIT :
                       return {
                           ...state,
                           smsLimitLoader : true
                       }
        case SETTING_SMS_LIMIT_SUCCESS :
                       let newUsers = [...state.user];
                       newUsers[action.index] = { ...state.user[action.index], smsLimit : Number(action.smsLimit) + Number(state.user[action.index].smsLimit) }
                       return {
                           ...state,
                           smsLimitLoader : false,
                           user : [...newUsers]
                       }
        case SETTING_SMS_LIMIT_ERROR :
                        return {
                            ...state,
                            smsLimitLoader : false
                        }
        case VERIFING_USER :
                        return {
                            ...state,
                            verifingLoader : true
                        }
        case VERIFING_USER_SUCCESS :
                       let newList = [...state.user];
                       newList[action.index] = { ...state.user[action.index], 
                                                userStatus : 1, 
                                                smsLimit : 10 }
                        return {
                            ...state,
                            verifingLoader : false,
                            user : [...newList]
                        }
        case VERIFING_USER_ERROR :
                        return {
                            ...state,
                            verifingLoader : false
                        }
        default : return state;
    }
}

export default adminReducer;