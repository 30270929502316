import { AUTHENTICATING_FAIL, AUTHENTICATING_SUCCESS, AUTHENTICATING, LOG_OUT, SET_ERROR } from '../Actions/actionTypes';

const initState = {
     token : null,
     loginLoader : false,
     error : { val : false, msg : '' }
}

const authReducer = (state = initState, action) => {
    switch(action.type) {
        case AUTHENTICATING : 
                         return {
                             ...state,
                             loginLoader : true   
                         }   
        case AUTHENTICATING_SUCCESS :
                         return {
                             ...state,
                             loginLoader : false,
                             token : action.token
                         } 
        case AUTHENTICATING_FAIL : 
                         return {
                             ...state,
                             loginLoader : false,
                             error : {
                                 ...state.error,
                                 val : true,
                                 msg : action.error
                             }
                         }
        case LOG_OUT : 
                        return {
                            ...state,
                            token : null
                        } 
        case SET_ERROR : 
                       return {
                           ...state,
                           error : {
                               val : false
                           }
                       } 
        default : return state;
    }
}

export default authReducer;