import React, { useState, useEffect } from 'react'
import { Modal, Fade, Backdrop, Typography, Switch, TextField, Button, CircularProgress } from '@material-ui/core'
import styled from 'styled-components';
import { connect } from 'react-redux';
import { setSmsLimit, verifyUser } from '../Store/Actions/adminAction';

function EditModal({ open, handleClose, selectedUser, setSmsLimit, smsLimitLoader, verifyUser, verifingLoader }) {

    const [smsLimit, setLimit] = useState(0)
    const [verified, setVerified] = useState(false);

    const smsLimitChangeHandler = e => {
        setLimit(e.target.value);
    }

    const verificationHandler = e => {
        verifyUser(selectedUser.index, selectedUser.email);
    }

    const saveSmsLimitHandler = () => {
        setSmsLimit(selectedUser.index, selectedUser.email, smsLimit);
    }

    useEffect(() => {
        setLimit(0)
        selectedUser.userStatus === 0 ? setVerified(false) : setVerified(true)
    },[selectedUser.smsLimit, selectedUser.userStatus])

    return (
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                open={open}
                onClose={handleClose}
                closeAfterTransition
                style={{ display : 'flex', justifyContent : 'center', alignItems : 'center' }}
                BackdropComponent={Backdrop}
                BackdropProps={{
                timeout: 500,
                }}
            >
                <Fade in={open}>
                    <EditContainer>
                        <Header>
                            <Typography variant='subtitle2'>
                                Change user config
                             </Typography>   
                         </Header>   
                         <Body>
                            <div>
                                <Typography variant='subtitle2' color='textSecondary'>
                                        Set user status
                                 </Typography> 
                                <div style={{ display : 'flex', alignItems : 'center' }}>
                                        <StyledSwitch
                                            checked={verified}
                                            color="primary"
                                            disabled={verified}
                                            onChange={verificationHandler}
                                            inputProps={{ 'aria-label': 'primary checkbox' }}
                                        />  
                                        { verifingLoader ? <CircularProgress size={20}/> : null }
                                </div>      
                             </div>  
                             <div>
                                 <Typography variant='subtitle2' color='textSecondary'>
                                        Increase user sms limit
                                 </Typography>  
                                 <TextField id="outlined-basic" 
                                            label="Sms limit" 
                                            variant="outlined"
                                            disabled={smsLimitLoader}
                                            value={smsLimit}
                                            onChange={smsLimitChangeHandler}
                                            style = {{ marginTop : '10px' }}
                                            size='small' />
                            </div>  
                          </Body> 
                          <Footer>
                            { smsLimitLoader ? <CircularProgress size={20}/> : null }
                             <Button size='small' 
                                     variant='contained'
                                     color='primary'
                                     onClick={saveSmsLimitHandler}
                                     disabled={smsLimitLoader}
                                     style={{ textTransform : 'capitalize', margin : '0px 10px' }}
                                     disableElevation>
                                  Save
                             </Button>
                             <Button size='small' 
                                     variant='contained'
                                     style={{ textTransform : 'capitalize' }}
                                     disableElevation
                                     disabled={smsLimitLoader}
                                     onClick={handleClose}>
                                  Cancel
                             </Button>
                          </Footer>  
                    </EditContainer>
                </Fade>
            </Modal>
    )
}

const mapStateToProps = state => {
    return {
        smsLimitLoader : state.adminReducer.smsLimitLoader,
        verifingLoader : state.adminReducer.verifingLoader,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSmsLimit : (index, email, limit) => dispatch(setSmsLimit(index, email, limit)),
        verifyUser : (index, email ) => dispatch(verifyUser(index, email ))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditModal)

const EditContainer = styled.div`
    width : 500px;
    background : #fff;
    outline : none;
    box-sizing : border-box;
    border-radius : 4px;
`;
const Header = styled.div`
   box-sizing : border-box;
   padding : 1rem;
   border-bottom : 1px solid rgba(0, 0, 0, 0.2);
`
const Body = styled.div`
   box-sizing : border-box;
   padding : 1rem;
   display : flex;
   justify-content : space-between;
`
const Footer = styled.div`
   box-sizing : border-box;
   padding : 0.7rem 1rem;
   border-top : 1px solid rgba(0, 0, 0, 0.2);
   display : flex;
   justify-content : flex-end;
   align-items: center;
`
const StyledSwitch = styled(Switch)`
   &&& {
       .MuiSwitch-colorPrimary.Mui-disabled {
            ${({ theme }) => `color : ${theme.palette.primary.main}`}
     } 
   }
`;