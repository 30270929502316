import { SUCCESS, ERROR, SET_SUCCESS_STATUS, SET_ERROR_STATUS } from './actionTypes'

export const success = message => {
    console.log(message)
    return dispatch => {
        dispatch({ type : SUCCESS, message : message })
    }
}

export const error = message => {
    return dispatch => {
        dispatch({ type : ERROR, message : message })
    }
}

export const setErrorStatus = () => {
    return dispatch => {
        dispatch({ type : SET_ERROR_STATUS })
    }
}

export const setSuccessStatus = () => {
    return dispatch => {
        dispatch({ type : SET_SUCCESS_STATUS })
    }
}