import React from 'react'
import styled from 'styled-components';
import logo from "../Assets/logo.png";
import { Typography, Avatar, IconButton } from '@material-ui/core'
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SearchIcon from '@material-ui/icons/Search';
import { connect } from 'react-redux'
import { logOut } from '../Store/Actions/authAction';

function Appbar({ logOut, nameFilter, searchChangedHandler }) {

    const logoutHandler = () => {
        logOut();
    }

    return (
        <Header>
            <div style={{ display : 'flex' }}>
                <img src={logo} style={{ width : '25px', marginRight : '10px' }} alt=''/>
                <StyledTypography color='textPrimary'>
                         SMS Nepal
                 </StyledTypography>  
            </div>
            <SearchBox>
                <input type='text' className='search-inp' value={nameFilter} onChange={searchChangedHandler} placeholder='Search...'/>
                <div className='search-icon'>
                    <SearchIcon color='inherit'/>
                </div>    
            </SearchBox>  
            <div style={{ display : 'flex', alignItems : 'center' }}>
                <StyledAvatar>
                    A
                </StyledAvatar>
                <IconButton onClick={logoutHandler}>
                    <ExitToAppIcon />
                </IconButton>   
            </div>     
        </Header>
    )
}

const mapDispatchToProps = dispatch => {
    return {
        logOut : () => dispatch(logOut())
    }
}
 
export default connect(null, mapDispatchToProps)(Appbar)

const Header = styled.div`
    height : 56px;
    box-sizing : border-box;
    border-bottom : 1px solid rgba(0,0,0,0.2);
    display : flex;
    padding : 0px 2rem;
    align-items : center;
    justify-content : space-between;
`;
const StyledTypography = styled(Typography)`
   &&& {
     font-family: 'Kanit', sans-serif;
     flex : 1;
     font-size : 20px;
   }
`
const StyledAvatar = styled(Avatar)`
   &&& {
      ${({ theme }) => `
            background : ${theme.palette.success.main};
            height : 30px;
            width : 30px;
            font-size : 15px;
            margin : 0px 10px;
      `} 
   }
`
const SearchBox = styled.div`
   width : 400px;
   height : 35px;
   display : flex;
   box-sizing : border-box;
   .search-inp {
       height : 100%;
       flex : 1;
       border-radius : 3px 0px 0px 3px;
       outline : none;
       border : 0;
       box-sizing : border-box;
       padding : 1rem;
       border : 1px solid rgba(0,0,0,0.1);
       font-size : 0.9em;
       background : #f5f5f5;
       &::placeholder {
           color : #9e9e9e;
       }
   }
   .search-icon {
       width : 50px;
       ${({ theme }) => `
            background : ${theme.palette.primary.main};
            border : 1px solid ${theme.palette.primary.main};
            color : #fff;
       `}
       border-radius : 0px 3px 3px 0px;
       display : flex;
       justify-content : center;
       align-items : center;
       cursor: pointer;
   }
`