import { SUCCESS, ERROR, SET_SUCCESS_STATUS, SET_ERROR_STATUS } from '../Actions/actionTypes'

const initState = {
    success : { value : false, message : '' },
    error : { value : false, message : '' }
}

const snackBarReducer = (state = initState, action) => {
    switch(action.type) {
        case SUCCESS : 
                return {
                    ...state,
                    success : {
                        ...state.success,
                        value : true,
                        message : action.message
                    }
                }
        case ERROR : 
                return {
                    ...state,
                    error : {
                        ...state.error,
                        value : true,
                        message : action.message
                    }
                }
        case SET_SUCCESS_STATUS : 
                return {
                    ...state,
                    success : {
                        ...state.success,
                        value : false
                    }
                }
        case SET_ERROR_STATUS : 
                return {
                    ...state,
                    error : {
                        ...state.error,
                        value : false
                    }
                }
        default : return state;
    }
}

export default snackBarReducer;
