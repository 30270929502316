import { LOADING_USER_DATA, LOADING_USER_DATA_SUCCESS, LOADING_USER_DATA_ERROR, LOAD_PAGE, SETTING_SMS_LIMIT, SETTING_SMS_LIMIT_SUCCESS,
        SETTING_SMS_LIMIT_ERROR, VERIFING_USER, VERIFING_USER_SUCCESS, VERIFING_USER_ERROR } from './actionTypes';
import { success, error } from './snackBarAction'        
import axios from 'axios';

const loadingUserData = () => {
    return {
        type : LOADING_USER_DATA
    }
}

const loadingUserDataSuccess = data => {
    return {
        type : LOADING_USER_DATA_SUCCESS,
        data : data
    }
}

const loadingUserDataError = () => {
    return {
        type : LOADING_USER_DATA_ERROR,
    }
}

export const loadUserData = (nameFilter, pageNo) => {
    return (dispatch, getState) => {
        dispatch(loadingUserData());
        const token = getState().authReducer.token;
        axios.get(`/allUsers?nameFilter=${nameFilter}&page=${pageNo}`,
                 { headers : { Authorization : `Bearer ${token}` } })
              .then(res => {
                  const user = res.data.filter(list => list.role !== 'ROLE_ADMIN');
                  dispatch(loadingUserDataSuccess(user))  
              })  
              .catch(err => {
                  dispatch(loadingUserDataError());
                  console.log(err.response)
              })
    }
}

export const loadTotalPage = (nameFilter) => {
    return (dispatch, getState) => {
        const token = getState().authReducer.token;
        axios.get(`/user/getTotalPages?nameFilter=${nameFilter}`,
            { headers : { Authorization : `Bearer ${token}` } })
            .then(res => {
                dispatch({ type : LOAD_PAGE, totalPage : res.data })
            })
            .catch(err => {
                console.log(err)
            })
    }
}

const settingSmsLimit = () => {
    return {
        type : SETTING_SMS_LIMIT
    }
}
const settingSmsLimitSuccess = (index, smsLimit) => {
    return {
        type : SETTING_SMS_LIMIT_SUCCESS,
        index : index,
        smsLimit : smsLimit
    }
}
const settingSmsLimitError = () => {
    return {
        type : SETTING_SMS_LIMIT_ERROR
    }
}

export const setSmsLimit = (index, email, smsLimit) => {
    return (dispatch, getState) => {
        const token = getState().authReducer.token;
        const prevSmsLimit = getState().adminReducer.user[index].smsLimit;
        dispatch(settingSmsLimit());
        axios.post(`/sms/increaseLimit?limit=${Number(smsLimit) + Number(prevSmsLimit)}&email=${email}`,
              null,
              { headers : { Authorization : `Bearer ${token}` } })
              .then(res => {
                  dispatch(settingSmsLimitSuccess(index, smsLimit));
                  dispatch(success('Users limit has been increased successfully.'))
              })
              .catch(err => {
                dispatch(settingSmsLimitError())
                if(err.response && err.response.status === 406){
                    dispatch(error(err.response.data.message))
                }
                else{
                    dispatch(error('Could not perform the operation.'))
                }
              })
    }
}

const verifingUser = () => {
     return {
         type : VERIFING_USER
     }
}

const verifingUserSuccess = index => {
    return {
        type : VERIFING_USER_SUCCESS,
        index : index
    }
}

const verifingUserError = () => {
    return {
        type : VERIFING_USER_ERROR
    }
}

export const verifyUser = (index, email) => {
    console.log(index, email);
    return (dispatch, getState) => {
        const token = getState().authReducer.token;
        dispatch(verifingUser())
        axios.post(`/user/verifyUser?email=${email}`,null,
            { headers : { Authorization : `Bearer ${token}` } })
            .then(res => {
                dispatch(verifingUserSuccess(index));
                dispatch(success('Users has been verified successfully.'))
            })
            .catch(err => {
                dispatch(verifingUserError())
                if(err.response && err.response.status === 406){
                    dispatch(error(err.response.data.message))
                }
                else{
                    dispatch(error('Could not perform the operation.'))
                }
            })
    }
}