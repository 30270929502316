export const AUTHENTICATING = 'AUTHENTICATING';
export const AUTHENTICATING_SUCCESS = 'AUTHENTICATING_SUCCESS';
export const AUTHENTICATING_FAIL = 'AUTHENTICATING_FAIL';

export const LOADING_USER_DATA = 'LOADING_USER_DATA';
export const LOADING_USER_DATA_SUCCESS = 'LOADING_USER_DATA_SUCCESS';
export const LOADING_USER_DATA_ERROR = 'LOADING_USER_DATA_ERROR';

export const SETTING_SMS_LIMIT = 'SETTING_SMS_LIMIT';
export const SETTING_SMS_LIMIT_SUCCESS = 'SETTING_SMS_LIMIT_SUCCESS';
export const SETTING_SMS_LIMIT_ERROR = 'SETTING_SMS_LIMIT_ERROR';

export const VERIFING_USER = 'VERIFING_USER';
export const VERIFING_USER_SUCCESS = 'VERIFING_USER_SUCCESS';
export const VERIFING_USER_ERROR = 'VERIFING_USER_ERROR';

export const LOAD_PAGE = 'LOAD_PAGE';

export const SUCCESS = 'SUCCESS'
export const ERROR = 'ERROR'
export const SET_SUCCESS_STATUS = 'SET_SUCCESS_STATUS'
export const SET_ERROR_STATUS = 'SET_ERROR_STATUS'

export const SET_ERROR = 'SET_ERROR'

export const LOG_OUT = 'LOG_OUT'