import { AUTHENTICATING, AUTHENTICATING_SUCCESS, AUTHENTICATING_FAIL, LOG_OUT, SET_ERROR } from './actionTypes';
import axios from 'axios';

const authenticating = () => {
    return {
        type : AUTHENTICATING
    }
}

const authenticatingSuccess = token => {
    localStorage.setItem('token', token);
    return {
        type : AUTHENTICATING_SUCCESS,
        token : token
    }
}

const authenticatingFail = error => {
    return {
        type : AUTHENTICATING_FAIL,
        error : error
    }
}

export const authenticate = (email, password) => {
    return dispatch => {
        dispatch(authenticating());
        axios.post(`/authenticate`, { username : email, password : password })
          .then(res => {
              if(res.status === 200){
                  if(res.data.role === 'ADMIN'){
                     dispatch(authenticatingSuccess(res.data.token));
                  }
                  else{
                     dispatch(authenticatingFail('Incorrect username or password.'))
                  }
              }
          })
          .catch(err => {
              dispatch(authenticatingFail('Incorrect username or password.'))
          })
    }
}

export const logOut = () => {
    localStorage.removeItem('token');
    return {
        type : LOG_OUT
    }
}

export const checkAuth = () => {
    return dispatch => {
        const token = localStorage.getItem('token');
        if(token) {
            dispatch(authenticatingSuccess(token));
        }
        else {
            logOut();
        }
    }
}
export const setError = () => {
    return dispatch => {
        setTimeout(() => {
            dispatch({ type : SET_ERROR });
        },[5000])
    }
}