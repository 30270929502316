import React from 'react'
import styled from 'styled-components';
import { TableContainer, Table, TableBody, TableHead, TableRow, TableCell, Chip, IconButton, Snackbar } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import EditModal from './EditModal';
import { connect } from 'react-redux'
import { setSuccessStatus, setErrorStatus } from '../Store/Actions/snackBarAction'

function Body({ user, success, error, setSuccessStatus, setErrorStatus }) {

    const [open, setOpen] = React.useState(false);
    const [openSnackBar, setSnackBar] = React.useState(false);
    const [openErrorSnackBar, setErrorSnackBar] = React.useState(false);
    const [selectedUser, setSelectedUser] = React.useState({ index : 0, email : '', smsLimit : 0, userStatus : 0 })

    const handleOpen = (index, email, smsLimit, userStatus) => {
        setSelectedUser({ ...selectedUser, index : index, email : email, smsLimit : smsLimit, userStatus : userStatus })
        setOpen(true);
      };
    
      const handleClose = () => {
        setOpen(false);
      };
    
      const handleCloseSnackBar = () => {
        setSnackBar(false);
      };

      const handleCloseErrorSnackBar = () => {
        setErrorSnackBar(false);
      };

      React.useEffect(() => {
         if(success.value) {
            setSnackBar(true);
            setSuccessStatus()
            setOpen(false);
         }
         if(error.value) {
            setErrorSnackBar(true);
            setErrorStatus()
         }
      },[success.value, error.value, setSuccessStatus, setErrorStatus])

    return (
        <Container>
            <EditModal open={open} handleClose={handleClose} selectedUser={selectedUser}/>
            <TableContainer style={{ width : '80%', height : 'auto' }}>
                { user.length !==0 ?
                 <Table aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell align="right">Email</TableCell>
                            <TableCell align="right">Phone no</TableCell>
                            <TableCell align="right">Total Message</TableCell>
                            <TableCell align="right">Total Sent</TableCell>
                            <TableCell align="right">Email Status</TableCell>
                            <TableCell align="right">User Status</TableCell>
                            <TableCell align="right">Edit</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            user.map((list, index) => 
                                    <TableRow key={list.email}>
                                        <TableCell component="th" scope="row">
                                            {list.name}
                                        </TableCell>
                                        <TableCell align="right">{list.email}</TableCell>
                                        <TableCell align="right">{list.phone}</TableCell>
                                        <TableCell align="right">{list.smsLimit}</TableCell>
                                        <TableCell align="right">{list.sent}</TableCell>
                                        <TableCell align="right">  
                                                <StyledChip label={ list.emailStatus === 0 ? 'Unverified' : 'Verified' } size='small' colorprop = { list.emailStatus === 0 ? 'secondary' : 'success' } /> 
                                        </TableCell>
                                        <TableCell align="right">  
                                                <StyledChip label={ list.userStatus === 0 ? 'Unverified' : 'Verified' } size='small' colorprop = { list.userStatus === 0 ? 'secondary' : 'success' } /> 
                                        </TableCell>
                                        <TableCell align="right">
                                            <IconButton size='small' 
                                                        onClick={handleOpen.bind(null, index, list.email, list.smsLimit, list.userStatus)}>
                                                <EditIcon fontSize='small'/>
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                )
                        }
                    </TableBody>
                </Table>
                :
                null
                }
            </TableContainer>
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={openSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseSnackBar}
                message={success.message}
                />
            <Snackbar
                anchorOrigin={{ vertical : 'top', horizontal : 'center' }}
                open={openErrorSnackBar}
                autoHideDuration={3000}
                onClose={handleCloseErrorSnackBar}
                message={error.message}
                />
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        user : state.adminReducer.user,
        success : state.snackBarReducer.success,
        error : state.snackBarReducer.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setSuccessStatus : () => dispatch(setSuccessStatus()),
        setErrorStatus : () => dispatch(setErrorStatus())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Body)

const Container = styled.div`
   height : calc(100vh - 56px - 50px);
   width : 100%;
   box-sizing : border-box;
   padding : 1rem;
   display : flex;
   justify-content : center;
   overflow-y : auto;
`;
const StyledChip = styled(Chip)`
   &&& {
       ${({ theme, colorprop }) => `
           color : #fff;
           background : ${theme.palette[colorprop].main};
       `}
   }
`
