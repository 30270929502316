import React from 'react'
import styled from 'styled-components';
import logo from '../Assets/logo.png'
import { Typography, TextField, Button, CircularProgress } from '@material-ui/core';
import { connect } from 'react-redux';
import { authenticate, setError } from '../Store/Actions/authAction';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

function Login({ loader, authenticate, error, setError }) {

    const [email, setEmail] = React.useState('');
    const [password, setPassword] = React.useState('');

    const authenticateHandler = () => {
        authenticate(email, password)
    }

    React.useEffect(() => {
        if(error.val){
            setError();
        }
    },[error, setError])

    React.useEffect(() => {
        document.title = 'SMS Nepal Admin - Login'
    },[])

    return (
        <Container>
            <FormContainer>
                 <div>
                     <img src={logo} style={{ color : '#fff',width : '35px' }} alt=''/>
                  </div>   
                  <Typography variant='subtitle1'>
                        SMS Nepal admin
                  </Typography>
                  <TextField
                        label="Username"
                        variant="outlined"
                        type = "text"
                        fullWidth
                        value={email}
                        disabled={loader}
                        onChange={ e => setEmail(e.target.value) }
                        style={{ marginTop : '1.8rem' }}
                    />
                    <TextField
                        label="Password"
                        variant="outlined"
                        type = "password"
                        disabled={loader}
                        fullWidth
                        value={password}
                        onChange={ e => setPassword(e.target.value) }
                        style={{ marginTop : '1.8rem' }}
                    />
                    {error.val ? 
                        <Alert>
                            <ErrorOutlineIcon style={{ marginRight : '10px' }}/>
                            <Typography variant='subtitle2' color='inherit'>
                                Incorrect username or password.
                            </Typography>
                        </Alert> 
                        : null
                    }  
                    <Button 
                        variant='contained' 
                        color='primary' 
                        fullWidth 
                        disabled={loader}
                        disableElevation 
                        onClick={authenticateHandler}
                        style={{ marginTop : '1.8rem', textTransform : 'capitalize' }}>
                          {loader ? <CircularProgress size={25} color='primary'/> : 'Login' }
                    </Button>
             </FormContainer>   
        </Container>
    )
}

const mapStateToProps = state => {
    return {
        loader : state.authReducer.loginLoader,
        error : state.authReducer.error,
    }
}

const mapDispatchToProps = dispatch => {
    return {
        authenticate : (email, password) => dispatch(authenticate(email, password)),
        setError : () => dispatch(setError())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Login);

const Container = styled.div`
  display : flex;
  align-items : center;
  justify-content : center;
  height : ${window.innerHeight}px;
`;
const FormContainer = styled.div`
   width : 400px;
   height : 500px;
   display : flex;
   flex-direction : column;
   align-items : center;
   padding: 1rem;
   box-sizing : border-box;
`;

const Alert = styled.div`
   width : 100%;
   box-sizing : border-box;
   padding : 0.8rem;
   color: rgb(97, 26, 21);
   background-color: rgb(253, 236, 234);
   display : flex; 
   align-items : center;
   margin-top : 20px;
`;